import React, { useEffect, useRef } from "react";
import {
  ObjectLoader,
  WebGLRenderer,
  Scene,
  PerspectiveCamera,
  Vector3,
  Mesh,
  MeshBasicMaterial,
  DirectionalLight,
  BackSide,
  Group,
} from "three";
import { getWidth } from "./viewport";
import styled from "styled-components";
// import './magic-button.less';
import OkidokiModel from "../../assets/models/amindimodel.json";
// import vote from "../../assets/audio/VOTE.wav";

var drag = 0.33;
var loader = new ObjectLoader();
var HALF_PI = Math.PI * 0.5;
var TWO_PI = Math.PI * 2;

var StyledMagicButton = styled.div`
  canvas {
    display: block;
    margin: 0 auto;
  }
`;

export default function MagicButton(props) {
  var domElement = useRef();
  var refs = useRef({
    pressing: null,
    isFirstMovement: true,
    isHorizontal: true,
    // velocity: { x: 0, y: 0 },
    mx: props.mouse.x, // Mouse move position
    my: props.mouse.y,
    initRotation: 0,
  });

  const rotation = useRef({
    base: 0,
    delta: 0,
  });
  // const [baseRotation, setBaseRotation] = useState(0);
  // const [currRotation, setCurrRotation] = useState(0);

  // const audioRef = useRef();
  var initial = useRef(props);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setup, []);
  useEffect(resize, [props.width, props.height]);
  useEffect(mousemove, [props.mouse]);

  function setup() {
    var renderer = new WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    var scene = new Scene();
    var camera = new PerspectiveCamera(70);

    renderer.setClearColor(0x000000, 0);
    camera.position.z = 30;

    addLights();
    loader.parse(OkidokiModel, start);

    domElement.current.appendChild(renderer.domElement);
    renderer.domElement.addEventListener("pointerdown", press, false);

    refs.current.renderer = renderer;
    refs.current.scene = scene;
    refs.current.camera = camera;

    return destroy;

    function destroy() {
      renderer.setAnimationLoop(null);
      renderer.domElement.removeEventListener("pointerdown", press, false);
      if (domElement.current) {
        domElement.current.removeChild(renderer.domElement);
      }
    }

    function start(model) {
      var grandparent = new Group();
      var parent = new Group();

      model.rotation.order = "YXZ";
      // model.userData.rotation = new Vector3();

      var outline = new Mesh(
        model.geometry,
        new MeshBasicMaterial({
          color: "white",
          side: BackSide,
          fog: false,
        }),
      );
      outline.rotation.order = "YXZ";

      new Group().add(outline);

      scene.add(outline.parent);
      parent.add(model);
      grandparent.add(parent);
      scene.add(grandparent);
      refs.current.model = model;

      // model.userData.dest = {
      //   rotation: new Vector3(),
      // };
      model.userData.grandparent = grandparent;
      model.userData.parent = parent;
      model.userData.outline = outline;

      renderer.setAnimationLoop(update);
    }

    function addLights() {
      const LIGHT_INTENSITY = 1;

      // overhead lights
      var light = new DirectionalLight(0xffffff, LIGHT_INTENSITY);
      light.position.y = 10;
      light.position.z = 5;
      light.position.x = 5;
      light.lookAt(new Vector3());

      scene.add(light);

      light = new DirectionalLight(0xffffff, LIGHT_INTENSITY);
      light.position.y = 10;
      light.position.z = 5;
      light.position.x = -5;
      light.lookAt(new Vector3());

      scene.add(light);

      // Under cube light
      light = new DirectionalLight(0xffffff, LIGHT_INTENSITY);
      light.position.y = -10;
      light.position.z = 5;
      light.position.z = 0;
      light.lookAt(new Vector3());

      scene.add(light);
    }

    function press(e) {
      e.preventDefault();
      e.stopPropagation();

      e.cancelBubble = true;
      e.returnValue = false;

      refs.current.pressing = Date.now();
      refs.current.isFirstMovement = true;
      // refs.current.mx = props.mouse.x;
      // console.log("props", props.mouse.x, "refs", refs.current.mx);
      refs.current.my = props.mouse.y;

      window.addEventListener("pointerup", release, false);

      return false;
    }

    function release(e) {
      const VOTE_THRESHOLD = HALF_PI / 3;
      const hasTurned = Math.abs(rotation.current.delta) > HALF_PI / 2;
      const hasVoted = Math.abs(rotation.current.delta) < VOTE_THRESHOLD;
      if (hasTurned) {
        if (rotation.current.delta > 0) {
          rotation.current.base += HALF_PI;
        } else {
          rotation.current.base -= HALF_PI;
        }
      }
      rotation.current.delta = 0;

      if (initial.current.onRelease && hasVoted) {
        // audioRef.current.play();
        var direction, side; //, velocity;
        // if (
        //   Math.abs(refs.current.velocity.x) >= Math.abs(refs.current.velocity.y)
        // ) {
        // Horizontal inertial spinning
        // velocity = refs.current.velocity.x;
        // if (velocity === 0) {
        //   direction = "none";
        // } else {
        //   direction = velocity < 0 ? "left" : "right";
        // }
        // } else {
        //   // Vertical inertial spinning
        //   velocity = refs.current.velocity.y;
        //   direction = velocity < 0 ? "up" : "down";
        // }

        // Calculate which face the cube resolves to.
        // var rotation = 0;
        // while (velocity > 0.001) {
        //   rotation += velocity;
        //   velocity *= drag * 2;
        // }
        // var rest = model.userData.dest.rotation.y; // + rotation;
        let rest = rotation.current.base;
        rest = Math.floor(rest / HALF_PI) * HALF_PI;
        side = Math.floor((4 * mod(rest, TWO_PI)) / TWO_PI);

        var duration = Date.now() - refs.current.pressing;
        var intensity = NaN;
        // var intensity = 0.1 * (duration / 1000) + refs.current.velocity.x;

        initial.current.onRelease(direction, side, intensity, duration);
      }
      refs.current.pressing = null;

      window.removeEventListener("pointerup", release, false);
    }
  }

  function resize() {
    var renderer = refs.current.renderer;
    var camera = refs.current.camera;

    renderer.setSize(props.width, props.height);

    camera.aspect = props.width / props.height;
    camera.updateProjectionMatrix();
  }

  function mousemove() {
    var width = getWidth();

    if (refs.current.pressing) {
      var dx = props.mouse.x - refs.current.mx;
      dx /= width;
      dx *= Math.PI * 6;
      rotation.current.delta = clampOneTurn(rotation.current.delta + dx);

      // var dy = props.mouse.y - refs.current.my;
      // dy /= height;
      // dy *= Math.PI * 6;

      // if (refs.current.isFirstMovement) {
      //   refs.current.isHorizontal = dx >= dy;
      //   refs.current.isFirstMovement = false;
      // }

      // if (refs.current.isHorizontal) {
      // refs.current.velocity.x = dx;
      // model.userData.rotation.y = refs.current.initRotation + currRotation;
      // } else {
      // TODO: This currently returns innaccurate values...
      // because Euler values are in YXZ rotating in one direction
      // changes the entire orientation for the other axis rotation.
      //   refs.current.velocity.y = dy;
      //   model.userData.rotation.x += dy;
      // }
    }

    refs.current.mx = props.mouse.x;
    refs.current.my = props.mouse.y;
  }

  function update() {
    var renderer = refs.current.renderer;
    var scene = refs.current.scene;
    var camera = refs.current.camera;
    var model = refs.current.model;

    if (model) {
      camera.position.z = 30;
      camera.lookAt(0, 0, 0);

      if (refs.current.pressing) {
        model.userData.grandparent.scale.z +=
          (0.5 - model.userData.grandparent.scale.z) * drag;
      } else {
        // model.userData.rotation.y += refs.current.velocity.x;
        // model.userData.rotation.x += refs.current.velocity.y;
        model.userData.grandparent.scale.z +=
          (1 - model.userData.grandparent.scale.z) * drag;
      }

      // Snap based on specific faces

      // model.userData.dest.rotation.y = rotation;
      model.rotation.y +=
        (rotation.current.base + rotation.current.delta - model.rotation.y) *
        drag;
      // model.userData.outline.rotation.y = model.rotation.y;

      // TODO: This currently returns innaccurate values...
      // model.userData.dest.rotation.x
      //   = Math.floor(model.userData.rotation.x / HALF_PI) * HALF_PI;
      // Overload z value to calculate the spread rotation across x and z axes
      // model.parent.rotation.x
      //   += (model.userData.dest.rotation.x - model.parent.rotation.x) * drag;
      // model.userData.outline.parent.rotation.x = model.parent.rotation.x;

      // refs.current.velocity.x *= drag * 2;
      // refs.current.velocity.y *= drag * 2;
      renderer.render(scene, camera);
    }
  }

  return (
    <>
      <StyledMagicButton ref={domElement} className="magic-button" />
      {/* <audio ref={audioRef}>
        <source src={vote} />
      </audio> */}
    </>
  );
}

function mod(v, l) {
  while (v < 0) {
    v += l;
  }
  return v % l;
}

function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

function clampOneTurn(num) {
  return clamp(num, -1 * HALF_PI, HALF_PI);
}
