import React, { useEffect, useRef, useState } from "react";
import VideoPlayer from "./video-player";
import MagicButton from "./magic-button";
import { getHeight, transposeEvent } from "./viewport";
import styled from "styled-components";
// import './picture-in-picture.less';

var styles = {
  size: 17,
  margin: 20,
};

var StyledPictureInPicture = styled.div`
  position: fixed;
  padding: ${styles.margin}px;
  border-radius: ${styles.size * 0.5}px;
  background: transparent;
  cursor: grab;
  pointer-events: auto;
  touch-action: none;
  overflow: hidden;
  transition: background-color 0.35s ease-in-out;

  &:hover,
  &.dragging {
    background: rgba(0, 0, 0, 0.33);
  }
  &.dragging {
    cursor: grabbing;
  }
`;

var margin = 20;
var drag = 0.125;

export default function PictureInPicture(props) {
  var raf = useRef();
  var domElement = useRef();
  var cursor = useRef({
    x: 0.5 * props.width + 2 * margin,
    y: getHeight() - (props.height + 2 * margin),
    dragging: false,
    pressing: false,
  });
  var [mouse, setMouse] = useState({ x: 0, y: 0 });
  var [dragging, setDragging] = useState(false);
  var [styles, setStyles] = useState({
    x: 0.5 * props.width + 2 * margin,
    y: getHeight() - (props.height + 2 * margin),
    scale: 1,
  });

  useEffect(setup, []);

  function setup() {
    window.addEventListener("pointermove", pointermove, false);
    animate();

    return destroy;

    function destroy() {
      if (raf.current) {
        cancelAnimationFrame(raf.current);
      }
      window.removeEventListener("pointermove", pointermove, false);
    }

    function pointermove(e) {
      var details = transposeEvent(e);
      var x = details.clientX;
      var y = details.clientY;

      setMouse({ x: x, y: y });

      if (cursor.current) {
        if (cursor.current.pressing) {
          cursor.current.dragging = true;
        }

        cursor.current.x = x;
        cursor.current.y = y;
      }
    }

    function animate() {
      raf.current = requestAnimationFrame(animate);

      if (!cursor.current) {
        return;
      }

      setStyles(updateStyles);
    }

    function updateStyles(styles) {
      var tx = cursor.current.dragging ? cursor.current.x : styles.x;
      var x = (tx - styles.x) * drag + styles.x;

      var ty = cursor.current.dragging ? cursor.current.y : styles.y;
      var y = (ty - styles.y) * drag + styles.y;

      var ts = cursor.current.dragging ? 1.25 : 1;
      var scale = (ts - styles.scale) * drag + styles.scale;

      return { x: x, y: y, scale: scale };
    }
  }

  function pointerdown(e) {
    window.addEventListener("pointerup", pointerup, false);
    setDragging(true);
    cursor.current.pressing = true;
  }

  function pointerup(e) {
    setDragging(false);
    cursor.current.dragging = false;
    cursor.current.pressing = false;
    window.removeEventListener("pointerup", pointerup, false);
  }

  return (
    <StyledPictureInPicture
      ref={domElement}
      onPointerDown={pointerdown}
      style={{
        transform: `translate(-50%, -50%) translate(${styles.x}px, ${styles.y}px) scale(${styles.scale})`,
      }}
      className={["picture-in-picture", dragging ? "dragging" : ""].join(" ")}
    >
      <VideoPlayer
        playerNumber={1}
        playlist={props.playlist}
        width={props.width}
        height={props.height}
        onReady={props.onReady}
        onDoubleClick={props.onDoubleClick}
        setting={props.settings}
        playing={props.playing}
        muted={true}
      />

      <MagicButton
        width={Math.min(props.width, props.height)}
        height={Math.min(props.width, props.height)}
        fogColor={props.fadeColor}
        notifications={props.notifications}
        onRelease={props.onButtonRelease}
        mouse={mouse}
      />
    </StyledPictureInPicture>
  );
}
