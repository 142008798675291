import React, { useEffect } from "react";
import styled from "styled-components";

import OKIDOKI_ANIM_MP4 from "../assets/video/okidoki-animated.mp4";
import OKIDOKI_ANIM_WEBM from "../assets/video/okidoki-animated.webm";

import config from "../config";

import Firebase from "../lib/Firebase";

const Wrapper = styled.div.attrs({
  className: "TicketCheck",
})`
  background-color: #000;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  video {
    max-width: 50vw;
    user-select: none;
  }
`;

export default function TicketCheck() {
  useEffect(() => {
    Firebase.login(
      config.firebaseConfig,
      config.firebaseAccessDbConfig,
      config.firebaseViewersDbConfig
    );
  }, []);

  return (
    <Wrapper>
      <video muted loop autoPlay controls={false}>
        <source src={OKIDOKI_ANIM_WEBM} type="video/webm"></source>
        <source src={OKIDOKI_ANIM_MP4} type="video/mp4"></source>
      </video>
    </Wrapper>
  );
}
