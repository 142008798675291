import React, { useEffect, useRef, useState } from "react";
import VideoPlayer from "./video-player";
import OverlayFade from "./overlay-fade";
import Equalizer from "./equalizer";
import styled from "styled-components";
// import './base-player.less';

import PlayArrowSvg from "../../assets/icons/play-arrow.svg";
import PauseSvg from "../../assets/icons/pause.svg";
import EqualizerSvg from "../../assets/icons/equalizer.svg";
import NoCastSvg from "../../assets/icons/no-cast.svg";
import ChromecastSvg from "../../assets/icons/chromecast.svg";
import AirplaySvg from "../../assets/icons/airplay.svg";
import CloseSvg from "../../assets/icons/close.svg";
import SettingsSvg from "../../assets/icons/settings.svg";

var styles = {
  size: 17,
  margin: 20,
  lineHeight: 20,
  black: "#000",
  white: "#fff",
  red: "#FF3232",
};

var StyledBasePlayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${styles.white};

  div.controls,
  div.settings,
  div.casting {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.66);

    ul {
      position: absolute;
      list-style: none;
      box-sizing: border-box;
    }
  }

  div.controls {
    width: 10%;
    right: -10%;
    transition: right 0.35s ease-in-out;

    &.active {
      right: 0;
    }

    ul {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-left: 1px solid rgba(255, 255, 255, 0.33);

      & > li {
        display: block;
        position: relative;
        flex: 1;

        cursor: pointer;
        overflow: hidden;
        text-indent: -9999px;
        box-sizing: border-box;

        background-position: center center;
        background-repeat: no-repeat;
        transition: opacity 0.35s ease-in-out;
        opacity: 0.7;

        &:not(:first-child) {
          border-top: 1px solid rgba(255, 255, 255, 0.33);
        }

        &.active,
        &:hover {
          opacity: 1;
        }

        &.play {
          background-image: url(${PlayArrowSvg});
          &.playing {
            background-image: url(${PauseSvg});
          }
        }
        &.volume {
          // background-image: url(${EqualizerSvg});
        }
        &.cast {
          background-image: url(${NoCastSvg});
          &.none {
            cursor: default;
            &:hover {
              opacity: 0.7;
            }
          }
          &.chromecast {
            background-image: url(${ChromecastSvg});
          }
          &.airplay {
            background-image: url(${AirplaySvg});
          }
        }
        &.settings {
          background-image: url(${SettingsSvg});
        }
      }
    }
  }

  div.casting,
  div.settings {
    width: 90%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0));
    right: 10%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.35s ease-in-out;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  div.casting {
    div {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      padding-right: ${styles.margin}px;
      text-align: right;

      p {
        font-size: ${styles.size * 3}px;
        line-height: ${styles.lineHeight * 3}px;
      }

      p.title {
        font-size: ${styles.size}px;
        text-transform: uppercase;
        letter-spacing: ${styles.size * 0.25}px;
      }
    }
  }

  div.settings {
    ul {
      max-height: 100%;
      overflow-y: auto;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      padding-right: ${styles.margin}px;
      text-align: right;

      li {
        font-size: ${styles.size}px;
        line-height: ${styles.lineHeight * 1.8}px;
        margin-top: ${styles.lineHeight * 0.5}px;
        margin-bottom: ${styles.lineHeight * 0.5}px;
        padding-left: ${styles.size}px;
        padding-right: ${styles.size}px;
        text-transform: uppercase;
        min-width: ${styles.size * 8}px;
        border: 2px solid transparent;
        border-radius: ${styles.lineHeight * 1.8}px;
        text-align: center;
        cursor: pointer;
        transition: border-color 0.35s ease-in-out;

        &.active,
        &:hover {
          border-color: rgba(255, 255, 255, 0.5);
        }
        &:hover {
          border-color: ${styles.white};
        }
      }
    }
  }

  /**
   * State based styles
   */

  &:not(.controls) {
    cursor: none !important;
  }
  &.settings {
    div.controls {
      ul > li.settings {
        background-image: url(${CloseSvg});
      }
    }
  }
  &.casting {
    div.controls {
      ul > li.cast {
        background-image: url(${CloseSvg});
      }
    }
  }
`;

export default function BasePlayer(props) {
  var domElement = useRef();
  var hideTimeout = useRef();

  var [controlsVisible, setControlsVisible] = useState(false);
  var [settingsVisible, setSettingsVisible] = useState(false);
  var [requesting, setRequesting] = useState(false);
  var [casting, setCasting] = useState(false);
  var [castType, setCastType] = useState("none");
  var [settings, setSettings] = useState([]);
  var [currentSetting, setCurrentSetting] = useState(0);

  useEffect(setup, []);

  function setup() {
    return function () {
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
      }
    };
  }

  function togglePlay() {
    if (props.onPlayButtonToggle) {
      props.onPlayButtonToggle(!props.playing);
    }
  }

  function toggleMuted() {
    if (props.onMuteButtonToggle) {
      props.onMuteButtonToggle(!props.muted);
    }
  }

  function toggleCast() {
    if (/none/i.test(castType)) {
      return;
    }
    setRequesting(function (requesting) {
      return !requesting;
    });
    setSettingsVisible(false);
  }

  function toggleSettings() {
    setCasting(false);
    setSettingsVisible(function (settingsVisible) {
      return !settingsVisible;
    });
  }

  function updateCast(e) {
    setCasting(e.active);
  }

  function updateSettings(settings) {
    setCurrentSetting(settings.current);
    setSettings(settings.levels);
  }

  /**
   * Interaction Handlers
   */
  function showControls() {
    setControlsVisible(true);
    hideControls();
  }

  function hideControls() {
    if (!hideTimeout) {
      return;
    }
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
    }
    hideTimeout.current = setTimeout(function () {
      if (!domElement.current) {
        return;
      }
      var cl = domElement.current.classList;
      if (cl.contains("settings") || cl.contains("casting")) {
        hideControls();
        return;
      }
      setControlsVisible(false);
    }, 5000);
  }

  return (
    <StyledBasePlayer
      ref={domElement}
      className={[
        "base-player",
        settingsVisible ? "settings" : "",
        casting ? "casting" : "",
        controlsVisible ? "controls" : "",
      ].join(" ")}
    >
      <VideoPlayer
        playerNumber={0}
        playlist={props.playlist}
        width={props.width}
        height={props.height}
        onReady={props.onReady}
        casting={requesting}
        onCastType={setCastType}
        onCast={updateCast}
        onSettings={updateSettings}
        onMouseMove={showControls}
        onSingleClick={showControls}
        onDoubleClick={props.onDoubleClick}
        setting={currentSetting}
        playing={props.playing}
        muted={props.muted}
      />

      <OverlayFade
        fadeColor={props.fadeColor}
        stopFading={props.stopFading}
        intensity={props.fadeIntensity}
        width={props.width}
        height={props.height}
      />

      <div className={["controls", controlsVisible ? "active" : ""].join(" ")}>
        <ul className="buttons">
          <li
            className={["play", props.playing ? "playing" : ""].join(" ")}
            onClick={togglePlay}
          >
            Play
          </li>
          <li
            className={["volume", props.muted ? "muted" : ""].join(" ")}
            onClick={toggleMuted}
          >
            <Equalizer muted={props.muted} />
          </li>
          <li
            className={["cast", castType, casting ? "active" : ""].join(" ")}
            onClick={toggleCast}
          >
            Cast
          </li>
          <li className="settings" onClick={toggleSettings}>
            Settings
          </li>
        </ul>
      </div>
      <div className={["settings", settingsVisible ? "active" : ""].join(" ")}>
        <ul>
          {settings.map(function (setting, i) {
            function click() {
              setCurrentSetting(i);
              if (props.onSettingsChange) {
                props.onSettingsChange(i);
              }
              setSettingsVisible(false);
            }
            return (
              <li
                className={i === currentSetting ? "active" : ""}
                key={i}
                onClick={click}
              >
                {!setting.label || setting.label === "0"
                  ? "Auto"
                  : setting.label}
              </li>
            );
          })}
        </ul>
      </div>
    </StyledBasePlayer>
  );
}
