import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import "firebase/functions";
import "firebase/firestore";

import history from "./History";

const N_SHARDS = 5;
// let's put this somewhere more sane
const PLAYER_IDS = ["vp-0", "vp-1"];

export type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

interface FirebaseApps {
  primaryApp: firebase.app.App | null;
  accessApp: firebase.app.App | null;
  viewersApp: firebase.app.App | null;
  // TODO: Figure out why this firebase.firestore type is rejected by TSC
  firestore: any | null;
}

type ViewReport = {
  id: string;
  time: firebase.firestore.FieldValue;
  shard: number;
  ticketId: string;
  p: (PlayerInfo | {})[];
};

type PlayerInfo = {
  //state
  s: string;
  //height
  h?: number;
  //bitrate
  b?: number;
};

class Firebase {
  firebase: FirebaseApps;

  constructor() {
    this.firebase = {
      primaryApp: null,
      accessApp: null,
      viewersApp: null,
      firestore: null,
    };
  }

  navigateToShow(showId: string) {
    history.push(`/show/${showId}`);
  }

  async bootstrapFirebaseServices(
    primaryConfig: FirebaseConfig,
    accessDbConfig: FirebaseConfig,
    viewersDbConfig: FirebaseConfig,
  ) {
    this.firebase.primaryApp = firebase.initializeApp(primaryConfig);
    this.firebase.accessApp = firebase.initializeApp(
      accessDbConfig,
      "accessApp",
    );
    this.firebase.viewersApp = firebase.initializeApp(
      viewersDbConfig,
      "viewersApp",
    );
    this.firebase.firestore = firebase.firestore();

    firebase.analytics();
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    firebase
      .auth(this.firebase.accessApp)
      .setPersistence(firebase.auth.Auth.Persistence.SESSION);
    firebase
      .auth(this.firebase.viewersApp)
      .setPersistence(firebase.auth.Auth.Persistence.SESSION);
  }

  async login(
    primaryConfig: FirebaseConfig,
    accessDbConfig: FirebaseConfig,
    viewersDbConfig: FirebaseConfig,
  ) {
    // START StreamIQ Login flow
    const pageUrl = new URL(window.location.href);

    let token = pageUrl.searchParams.get("t");

    const existingToken = localStorage.getItem("streamiq-token");

    // user has no token at all, copy pasted link from friend case
    if (!token && !existingToken) {
      history.push("/error/no-ticket");
      return;
    }

    if (existingToken && !token) {
      // Refresh case -- user has no token in URL but does in localStorage
      token = existingToken;
    }

    if (!this.firebase.primaryApp || !this.firebase.accessApp) {
      await this.bootstrapFirebaseServices(
        primaryConfig,
        accessDbConfig,
        viewersDbConfig,
      );
    }

    let streamIqLogin = firebase.functions().httpsCallable("streamIqLogin");
    let showId: string;

    let classThis = this;

    if (existingToken && token !== existingToken) {
      firebase.auth().signOut();
    }

    firebase.auth().onAuthStateChanged(function (user) {
      // User is already authed (in a session)
      if (user) {
        console.log("Authenticated");

        //get claims now that we're logged in
        user.getIdTokenResult().then(function (tokenResult) {
          console.log("Show", tokenResult.claims.show_id);
          showId = tokenResult.claims.show_id;

          if (!window.location.pathname.includes("show")) {
            localStorage.setItem("streamiq-token", token as string);
            classThis.navigateToShow(showId);
          }
        });
      } else {
        console.log("user is signed out");

        if (!(typeof token === "string") || token.length === 0) {
          // Throwing an HttpsError so that the client gets the error details.
          window.location.assign("https://teamokidoki.com");
          throw new Error(
            "The page must be loaded with " +
              'one argument "token" containing a valid ticket token.',
          );
        }

        // token is the long lived ticket token to access these stream tokens
        streamIqLogin({ token })
          .then(function (result: any) {
            // `result` is the 60-second lifetime length token to maintain in the video stream
            // sessionId = result.sessionId;
            const payload = atob(
              result.data.payload.substring(0, result.data.payload.length - 28),
            );

            const streamIQCustomToken = payload.substring(
              0,
              payload.length - 28,
            );

            firebase
              .auth()
              .signInWithCustomToken(streamIQCustomToken)
              .catch(function (error) {
                console.log("auth error", error);
              });

            if (classThis.firebase.accessApp) {
              firebase
                .auth(classThis.firebase.accessApp)
                .signInWithCustomToken(streamIQCustomToken)
                .catch(function (error) {
                  console.log("auth error", error);
                });
            }

            if (classThis.firebase.viewersApp) {
              firebase
                .auth(classThis.firebase.viewersApp)
                .signInWithCustomToken(streamIQCustomToken)
                .catch(function (error) {
                  console.log("auth error", error);
                });
            }
          })
          .catch(function (error) {
            console.log("streamiq login error", error);
          });

        if (firebase.auth().currentUser) {
          classThis.navigateToShow(showId);
        }
      }
    });
  }

  getFirebaseApp(appName: string) {
    return firebase.apps.find((app) => app.name === appName);
  }

  async makeViewReport(ticketId: string, showId: string) {
    const firestore = this.firebase.firestore;

    if (!firestore) {
      return console.warn(
        "tried to send view report without firestore initialized, this should not happen",
      );
    }

    const playerInfos = PLAYER_IDS.map((playerId) => {
      // @ts-ignore
      const player = window.jwplayer(playerId);
      if (!player) {
        console.warn(
          `tried to send view report without jwplayer, this should not happen (${playerId})`,
        );
        return {};
      }
      try {
        const { level } = player.getVisualQuality();
        return {
          s: player.getState() || "unknown",
          h: level.height || "unknown",
          b: level.bitrate || "unknown",
        };
      } catch (error) {
        console.warn(`error getting info from jwplayer for ${playerId}`, error);
        return {};
        // there was an error getting info from this player
      }
    });

    const doc = {
      time: firebase.firestore.FieldValue.serverTimestamp(),
      shard: Math.floor(Math.random() * N_SHARDS),
      ticketId,
      p: playerInfos,
    } as ViewReport;

    const batch = firestore.batch();
    const timeRef = firestore
      .collection("shows")
      .doc(showId)
      .collection("tickets")
      .doc(ticketId);

    batch.update(timeRef, { metric_last_write: doc.time });

    const viewRef = firestore
      .collection("shows")
      .doc(showId)
      .collection("views")
      .doc();

    batch.set(viewRef, doc);
    doc.id = viewRef.id;

    await batch.commit().catch((error: firebase.FirebaseError) => {
      console.error("error saving viewer progress", error);
    });
  }

  async show(): Promise<
    [firebase.database.Database, firebase.database.Database]
  > {
    const primaryApp = this.getFirebaseApp("[DEFAULT]");
    const accessApp = this.getFirebaseApp("accessApp");

    if (!primaryApp || !accessApp) {
      history.push("/");
    }

    const database = firebase.database(primaryApp);
    const accessDb = firebase.database(accessApp);

    firebase.analytics();

    return [database, accessDb];
  }
}

export default new Firebase();
