import React, { useEffect, useRef, useState } from "react";
import BasePlayer from "./components/base-player";
import PictureInPicture from "./components/picture-in-picture";
import { getWidth, getHeight } from "./components/viewport";
import styled from "styled-components";
import BackgroundTexture from "../assets/images/background-min.jpg";
// import './pip-ui.less';

var aspect = 9 / 16;

var styles = {
  size: 17,
  margin: 20,
  lineHeight: 20,
  black: "#000",
  white: "#fff",
  red: "#FF3232",
};

var StyledPipUI = styled.div`

  * {
    margin: 0;
    padding: 0;
    user-select: none;
  }

  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;

  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-size: ${styles.size}px;
  line-height: ${styles.lineHeight}px;
  z-index: 0;
  overflow: hidden;

  &.started {
    div.lobby {
      opacity: 0;
      pointer-events: none;
    }
  }

  div.navigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    pointer-events: none;
    z-index: 50;
  }

  div.status {
    position: absolute;
    top: ${styles.margin}px;
    left: ${styles.margin}px;
    color: ${styles.white};
    font-size: ${styles.size * 0.5}px;
    text-transform: uppercase;
    letter-spacing: ${styles.size * 0.1}px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: ${styles.lineHeight}px;
    padding: 0 ${styles.margin * 0.5}px;
    span:before {
      position: relative;
      display: inline-block;
      width: ${styles.size * 0.33}px;
      height: ${styles.size * 0.33}px;
      background: ${styles.red};
      content: "";
      border-radius: 50%;
      margin-right: ${styles.size * 0.3}px;
      margin-left: -${styles.size * 0.15}px;
      animation-name: pulse;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }

  div.lobby {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${styles.black};
    z-index: 200;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.35s ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${BackgroundTexture});
    h3 {
      color: ${styles.white};
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 33vh;
      height: 15vh;
      border-radius: ${styles.size * 0.25}px;
      box-shadow: 0 ${styles.size}px ${styles.lineHeight}px ${styles.black};
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translate(-50%,-50%);
      letter-spacing: 4px;
      font-size: ${styles.size * 0.8}px;
      line-height: 15vh;
      transition box-shadow 0.35s ease-in-out;
      cursor: pointer;
      padding: 0 ${styles.lineHeight * 2}px;
      box-sizing: border-box;
      &:active {
        box-shadow: 0 ${styles.size * 0.25}px ${styles.lineHeight * 0.25}px ${
  styles.black
};
      }
    }
  }

  &.mobile {
    @media screen and (orientation: portrait) {
      transform: translate(-50%, -50%) rotate(90deg);
      transform-origin: center;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      width: 100vh;
      height: 100vw;
    }
  }

  @keyframes pulse {

    0% {
      opacity: 0.33;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.33;
    }

  }

`;

export default function PipUI(props) {
  var videos = useRef({ performer: null, ambient: null });

  var [width, setWidth] = useState(getWidth());
  var [height, setHeight] = useState(getHeight());
  var [isMobile, setIsMobile] = useState(window.navigator.maxTouchPoints > 0);
  var [playing, setPlaying] = useState(false);
  var [started, setStarted] = useState(false);
  var [muted, setMuted] = useState(false);
  var [fading, setFading] = useState(0);
  var [fadeColor, setFadeColor] = useState("78FF8E");
  var [waiting, setWaiting] = useState(true);
  var [settings, setSettings] = useState(0);

  useEffect(setup, []);

  function setup() {
    window.addEventListener("resize", resize, false);
    window.addEventListener("orientationchange", resize, false);

    return destroy;

    function resize() {
      if (window.navigator.maxTouchPoints > 0) {
        document.oncontextmenu = function () {
          return false;
        };
      }
      setIsMobile(window.navigator.maxTouchPoints > 0);
      setWidth(getWidth());
      setHeight(getHeight());
    }

    function destroy() {
      window.removeEventListener("resize", resize, false);
      window.removeEventListener("orientationchange", resize, false);
    }
  }

  function playButtonPressed(state) {
    setPlaying(state);
  }

  function muteButtonPressed(state) {
    setMuted(state);
  }

  function swapVideo() {
    if (!(videos.current.performer && videos.current.ambient)) {
      return;
    }

    var p1 = videos.current.performer.parentElement;
    var p2 = videos.current.ambient.parentElement;
    p1.appendChild(videos.current.ambient);
    p2.appendChild(videos.current.performer);

    // var a = videos.current.performer.getContainer();
    // var b = videos.current.ambient.getContainer();
    //
    // var p1 = a.parentElement;
    // var p2 = b.parentElement;
    //
    // p1.appendChild(b);
    // p2.appendChild(a);
  }

  function fade(direction, side, velocity, duration) {
    const sideToColor = {
      // Comments correlate to the letters on each side of Amindis "nice" cube
      0: "rgba(255, 218, 0, 0.75)", // n
      1: "rgba(255, 139, 0, 0.75)", //e correct
      2: "rgba(82, 218, 222, 0.75)", // c correct
      3: "rgba(255, 0, 163, 0.75)", // i
    };
    setFading(0.5);
    setFadeColor(sideToColor[side]);
    if (props.onInteraction) {
      props.onInteraction(direction, side, velocity, duration);
    }
  }

  function start() {
    if (waiting) {
      return;
    }
    window.jwplayer("vp-0").play();
    setStarted(true);
    setPlaying(true);
  }

  function onPerfomerReady(jw, video) {
    videos.current.performer = video;
    setWaiting(false);
  }

  function onAmbientReady(jw, video) {
    videos.current.ambient = video;
  }

  function changeSettings(index) {
    setSettings(index);
  }

  function getPerformerStream() {
    for (var i = 0; i < props.playlist.length; i++) {
      var info = props.playlist[i];
      if (/performer/i.test(info.title)) {
        return info;
      }
    }
    return null;
  }

  function getAmbientStream() {
    for (var i = 0; i < props.playlist.length; i++) {
      var info = props.playlist[i];
      if (/ambient/i.test(info.title)) {
        return info;
      }
    }
    return null;
  }

  return (
    <StyledPipUI
      id="pip-ui"
      style={{ width: width, height: height }}
      className={[isMobile ? "mobile" : "", started ? "started" : ""].join(" ")}
    >
      <div>
        <BasePlayer
          playlist={[getPerformerStream()]}
          playing={playing}
          muted={muted}
          fadeIntensity={fading}
          fadeColor={fadeColor}
          stopFading={() => setFading(0)}
          onDoubleClick={swapVideo}
          onSettingsChange={changeSettings}
          onPlayButtonToggle={playButtonPressed}
          onMuteButtonToggle={muteButtonPressed}
          onReady={onPerfomerReady}
          notifications={props.notifications}
          width={width}
          height={height}
        />
        <div className="navigation">
          <PictureInPicture
            playlist={[getAmbientStream()]}
            onReady={onAmbientReady}
            onDoubleClick={swapVideo}
            onButtonRelease={fade}
            onInteraction={props.onInteraction}
            fadeColor={fadeColor}
            notifications={props.notifications}
            playing={playing}
            settings={settings}
            width={width * 0.2}
            height={width * 0.2 * aspect}
          />
          <div className="status">
            <span>Live</span>
          </div>
        </div>
      </div>
      <div className="lobby">
        <h3
          onClick={start}
          style={{ backgroundImage: `url(${props.enterButtonImage})` }}
        >
          {waiting ? "Loading..." : "Enter"}
        </h3>
      </div>
    </StyledPipUI>
  );
}
