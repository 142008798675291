const firebaseConfig = {
  apiKey: "AIzaSyAtAOi4AItDOI2E81cw7XN5ZEkgvAC1KDQ",
  authDomain: "streamiq-bellion.firebaseapp.com",
  databaseURL: "https://streamiq-bellion-config.firebaseio.com",
  projectId: "streamiq-bellion",
  storageBucket: "streamiq-bellion.appspot.com",
  messagingSenderId: "984925440091",
  appId: "1:984925440091:web:aa19dbcfcfb3942cd4177e",
  measurementId: "G-HQNTDRLC8Y",
};

const firebaseAccessDbConfig = {
  apiKey: "AIzaSyAtAOi4AItDOI2E81cw7XN5ZEkgvAC1KDQ",
  authDomain: "streamiq-bellion.firebaseapp.com",
  databaseURL: "https://streamiq-bellion.firebaseio.com",
  projectId: "streamiq-bellion",
  storageBucket: "streamiq-bellion.appspot.com",
  messagingSenderId: "984925440091",
  appId: "1:984925440091:web:aa19dbcfcfb3942cd4177e",
  measurementId: "G-HQNTDRLC8Y",
};

const firebaseViewersDbConfig = {
  apiKey: "AIzaSyAtAOi4AItDOI2E81cw7XN5ZEkgvAC1KDQ",
  authDomain: "streamiq-bellion.firebaseapp.com",
  databaseURL: "https://streamiq-bellion-viewers.firebaseio.com",
  projectId: "streamiq-bellion",
  storageBucket: "streamiq-bellion.appspot.com",
  messagingSenderId: "984925440091",
  appId: "1:984925440091:web:aa19dbcfcfb3942cd4177e",
  measurementId: "G-HQNTDRLC8Y",
};

const ConfigObject = {
  firebaseConfig,
  firebaseAccessDbConfig,
  firebaseViewersDbConfig
}

export default ConfigObject;
