import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "./lib/History";

import ErrorRoutes from "./views/ErrorRoutes";
import Show from "./views/Show";
import TicketCheck from "./views/TicketCheck";

import "./App.css";

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact>
          <TicketCheck />
        </Route>
        <Route path="/show">
          <Show />
        </Route>
        <ErrorRoutes />
      </Switch>
    </Router>
  );
}

export default App;
