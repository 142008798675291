import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import MagicButton from "../components/components/magic-button";
import { transposeEvent } from "../components/components/viewport";

export default function ErrorRoutes() {
  var [mouse, setMouse] = useState({ x: 0, y: 0 });

  useEffect(setup, []);

  function setup() {
    window.addEventListener("pointermove", pointermove, false);

    return destroy;

    function destroy() {
      window.removeEventListener("pointermove", pointermove, false);
    }

    function pointermove(e: any) {
      var details = transposeEvent(e);
      var x = details.clientX;
      var y = details.clientY;

      setMouse({ x: x, y: y });
    }
  }

  return (
    <Route path="/error">
      <div
        id="show"
        style={{
          alignItems: "center",
          color: "white",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
          width: "100%",
          maxWidth: "600px",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Route exact path="/error/no-ticket">
          <p>
            We couldn't find a valid ticket! Click on the link in your ticket
            email to try again.
          </p>
        </Route>
        <Route exact path="/error/ticket-in-use">
          <p>
            It looks like someone else is watching the show with your ticket!
          </p>
          <p>
            If you got your ticket from someone else, make sure you're both
            using different ticket links. If this is yours, click the link in
            the email again or contact support:{" "}
            <a style={{ color: "inherit" }} href="mailto:support@oki.live">
              support@oki.live
            </a>
          </p>
        </Route>
        <Route exact path="/error/button">
          <MagicButton
            width={400}
            height={400}
            // fogColor={props.fadeColor}
            // notifications={props.notifications}
            onRelease={(dir: any) => console.log(dir)}
            mouse={mouse}
          />
        </Route>
      </div>
    </Route>
  );
}
