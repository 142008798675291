import React from "react";

export default function BeforeShow() {
  return (
    <div
      id="show"
      style={{
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <p>Show has not started yet!</p>
    </div>
  );
}
