import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
// import './overlay-fade.less';

var StyledOverlayFade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

var drag = 0.0625;

export default function OverlayFade(props) {
  var domElement = useRef();
  var animating = useRef(false);
  var intensity = useRef();
  var top = useRef({
    dest: 100 * Math.abs(props.intensity),
    value: 0,
  });
  const color = useRef();

  var [opacity, setOpacity] = useState(0);

  // eslint-disable-next-line
  useEffect(setup, []);
  useEffect(trigger, [props.intensity]);
  useEffect(() => {
    color.current = props.color;
  }, [props.color]);

  function setup() {
    animating.current = true;
    requestAnimationFrame(animate);

    return destroy;

    function destroy() {
      animating.current = false;
    }

    function animate() {
      intensity.current -= intensity.current * (drag * 0.25);
      top.current.value += (top.current.dest - top.current.value) * (drag * 4);

      // var t = Math.min(Math.floor(top.current.value), 100);

      let opacity = Math.min(1, intensity.current);
      if (opacity < 0.05) {
        opacity = 0;
        props.stopFading();
      }
      setOpacity(opacity);
      // setBackground(
      //   `radial-gradient(circle at 50%, rgba(79, 110, 128, 0) ${100 - t}%, ${
      //     color.current
      //   } 100%)`,
      // );

      if (animating.current) {
        requestAnimationFrame(animate);
      }
    }
  }

  function trigger() {
    var v = 100 * Math.abs(props.intensity);
    intensity.current = 0.1 * v;
    top.current.value = 0;
    top.current.dest = 2 * v;
  }

  return (
    <StyledOverlayFade
      ref={domElement}
      style={{
        opacity: opacity,
        background: `radial-gradient(circle at 50%, rgba(79, 110, 128, 0) ${
          100 - 50
        }%, ${props.fadeColor} 100%)`,
      }}
      className="overlay-fade"
    />
  );
}
