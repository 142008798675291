import React from 'react';
import styled from 'styled-components';
// import './equalizer.less';

var styles = {
  white: '#fff',
  size: 24
};

var StyledEqualizer = styled.div`

  height: ${styles.size}px;
  width: ${styles.size}px;
  margin: 0 0;
  padding: 0 0 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;

  .ec {
    flex-grow: 1;
    margin: 0 10% 0 0;
    padding: 0;
    height: 100%;
    position: relative;
    list-style-type: none;
    &:last-child {
      margin-right: 0;
    }
  }
  .cb {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: 10%;
    width: 100%;
    background: ${styles.white};
    opacity: 1;
    transition: none;
  }

  &:not(.muted) {
    .ec:nth-child(1) .cb {
      animation: bounce 2s 1s ease-out alternate infinite;
    }

    .ec:nth-child(2) .cb {
      animation: bounce 2s 0.5s ease-out alternate infinite;
    }

    .ec:nth-child(3) .cb {
      animation: bounce 2s 1.5s ease-out alternate infinite;
    }
  }

  @keyframes bounce {
    0% {
      height: 10%;
      background: ${styles.white};
    }
    10% {
      height: 30%;
      background: ${styles.white};
    }
    20% {
      height: 50%;
      background: ${styles.white};
    }
    30% {
      height: 20%;
      background: ${styles.white};
    }
    40% {
      height: 70%;
      background: ${styles.white};
    }
    50% {
      height: 90%;
      background: ${styles.white};
    }
    60% {
      height: 30%;
      background: ${styles.white};
    }
    70% {
      height: 80%;
      background: ${styles.white};
    }
    80% {
      height: 50%;
      background: ${styles.white};
    }
    90% {
      height: 30%;
      background: ${styles.white};
    }
    100% {
      height: 10%;
      background: ${styles.white};
    }
  }

`;

export default function Equalizer(props) {
  return (
    <StyledEqualizer className={ ['equalizer', props.muted ? 'muted' : ''].join(' ') }>
      <ol className="ec">
        <li className="cb"></li>
      </ol>
      <ol className="ec">
        <li className="cb"></li>
      </ol>
      <ol className="ec">
        <li className="cb"></li>
      </ol>
    </StyledEqualizer>
  );
}
