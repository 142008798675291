import React, { useState, useEffect } from "react";
import firebase from "firebase/app";

import Firebase from "../lib/Firebase";
import history from "../lib/History";

import Cookies from "js-cookie";
import BeforeShow from "./BeforeShow";
import AfterShow from "./AfterShow";
import DuringShow from "./DuringShow";

enum StreamURLTitle {
  Performer = "Performer View",
  Ambient = "Ambient View",
}

declare global {
  interface Window {
    reportInterval: any;
    cookieInterval: any;
  }
}

interface FirebaseShowStatus {
  active: boolean;
  ended: boolean;
  require: string | null | undefined;
}

enum ShowStatus {
  WAITING = "waiting",
  ACTIVE = "active",
  ENDED = "ended",
  UNDETERMINED = "undetermined",
}

interface ProtectedData {
  ambient: string;
  performer: string;
}

export default function Show() {
  const [showStatus, setShowStatus] = useState<ShowStatus>(
    ShowStatus.UNDETERMINED,
  );

  const [playlist, setPlaylist] =
    useState<{ title: StreamURLTitle; file: string }[] | undefined>();
  const [showId, setShowId] = useState("");
  const [ticketId, setTicketId] = useState("");
  // States specific to StreamIQ chat functionality
  const [user, setUser] = useState<any>();
  const [idToken, setIdToken] = useState<any>();

  // fetch all parameters from CMS to control colors or anims. or whatever
  useEffect(() => {
    async function authStreamIQ() {
      const [primaryDb, accessDb] = await Firebase.show();

      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          setUser(user);
          user.getIdTokenResult().then(function (idTokenResult) {
            const showId = idTokenResult.claims.show_id;

            setShowId(showId);
            setTicketId(idTokenResult.claims.ticket_id);
            setIdToken(idTokenResult);

            const refreshCookie = async function () {
              console.log("Adding CDN cookie!", `cookie/${showId}`);
              let cookieRef = accessDb.ref(`cookie/${showId}`);
              // let sig = await cookieRef.once("value");
              const cookieUpdateHandler = function (snapshot: any) {
                snapshot.forEach((c: any) => {
                  Cookies.set(c.key, c.val().content, {
                    domain: c.val().domain,
                    path: c.val().path,
                    sameSite: "Lax",
                  });
                });
              };
              cookieRef
                .once("value")
                .then(cookieUpdateHandler)
                .catch(() => {
                  localStorage.clear();
                  clearInterval(window.cookieInterval);
                  window.cookieInterval = null;
                  history.push("/error/ticket-in-use");
                });
            };

            const attachCookie = async () => {
              if (window.cookieInterval) {
                return;
              }
              await refreshCookie();
              window.cookieInterval = setInterval(() => {
                refreshCookie();
              }, 15 * 1000);
            };

            attachCookie();

            const watchShowStatus = async function () {
              const statusRef = primaryDb.ref(`public/${showId}/status`);
              const protectedRef = primaryDb.ref(`protected/${showId}`);
              function showStatusHandler(
                statusSnapshot: firebase.database.DataSnapshot,
              ) {
                const status = statusSnapshot.val() as FirebaseShowStatus;
                const { ended } = status;
                protectedRef
                  .once("value")
                  .then((protectedSnapshot: firebase.database.DataSnapshot) => {
                    setShowStatus(ShowStatus.ACTIVE);
                    const protectedData = protectedSnapshot.val()
                      .data as ProtectedData;
                    setPlaylist([
                      {
                        title: StreamURLTitle.Performer,
                        file: protectedData.performer,
                      },
                      {
                        title: StreamURLTitle.Ambient,
                        file: protectedData.ambient,
                      },
                    ]);
                  })
                  .catch((_error) => {
                    if (ended) {
                      setShowStatus(ShowStatus.ENDED);
                    } else {
                      setShowStatus(ShowStatus.WAITING);
                    }
                  });
              }
              statusRef.once("value", showStatusHandler);
              statusRef.on("value", showStatusHandler);
            };
            watchShowStatus();
          });
        } else {
          // User is signed out.
          window.location.pathname = "/";
        }
      });
    }
    authStreamIQ();
  }, []);

  // useEffect(() => {
  // if (!showId) return;
  //
  // /**
  //  * TODO: This is currently hardcoded and pulled directly from the CRM.
  //  * This is not a good pattern. We should pull these styles from S3 when we
  //  * write them at the end of the "event creation" process in our CRM.
  //  */
  // const configUrl = `https://okidoki-config.s3.us-east-2.amazonaws.com/shared/shows/${showId}/config.json`;
  // fetch(configUrl)
  // .then((res) => res.json())
  // .then((json) => {
  // console.log("show config...");
  // console.dir(json);
  // setShowConfig(json);
  // })
  // .catch(console.error);
  // }, [showId]);
  //
  if (showStatus === ShowStatus.WAITING) {
    return <BeforeShow />;
  }
  if (showStatus === ShowStatus.ENDED) {
    return <AfterShow />;
  }
  if (showStatus === ShowStatus.ACTIVE) {
    return (
      <DuringShow
        showId={showId}
        idToken={idToken}
        ticketId={ticketId}
        playlist={playlist}
        user={user}
      />
    );
  }
  return <div id="show" />;
}
