import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
// import './video-player.less';

var StyledVideoPlayer = styled.div`
  .jwplayer {
    position: relative;
    z-index: -1;
  }
`;

export default function VideoPlayer(props) {
  var domElement = useRef();
  var jw = useRef();
  var casting = useRef(false);
  var video = useRef();
  var clicks = useRef(0);
  var clickTimeout = useRef();
  var initial = useRef(props);
  var [id, setId] = useState();
  var [castType, setCastType] = useState("none");

  useEffect(setup, [props.playerNumber]);
  useEffect(create, [id]);
  useEffect(resize, [props.width, props.height]);
  useEffect(updateCastType, [castType]);
  useEffect(updatePlaying, [props.playing]);
  useEffect(updateMuted, [props.muted]);
  useEffect(toggleCast, [props.casting]);
  useEffect(updateSettings, [props.setting]);

  function setup() {
    var selector = `vp-${props.playerNumber}`;
    setId(selector);

    return destroy;

    function destroy() {
      if (!jw.current) {
        return;
      }
      jw.current.off("all");
    }
  }

  function create() {
    if (!id) {
      return;
    }

    jw.current = window.jwplayer(id).setup({
      playlist: initial.current.playlist,
      width: initial.current.width,
      height: initial.current.height,
      stretching: "fill",
      mute: initial.current.muted,
      repeat: true,
      withCredentials: true,
      casting: {},
    });

    jw.current.on("ready", function () {
      video.current = domElement.current.querySelector("video");
      video.current.disablePictureInPicture = true;
      video.current.controlsList = "nodownload";

      // jw.current.on('all', console.log);
      // TODO: Event for when stream ends: jw.current.on('complete');

      jw.current.on("cast", function (e) {
        // console.log(e);
        if (e.available) {
          if (window.cast) {
            /**
             * Chromecast Detection
             */
            var state =
              window.cast.framework.CastContext.getInstance().getCastState();
            if (!/NO_DEVICES_AVAILABLE/i.test(state)) {
              setCastType("chromecast");
              // window.cast.framework.CastContext.getInstance().requestSession() // Returns promise
            }
          }
        }
        if (initial.current.onCast) {
          initial.current.onCast(e);
        }
      });
      if (initial.current.onSettings) {
        jw.current.on("levels", function (e) {
          initial.current.onSettings({
            current: jw.current.getCurrentQuality(),
            levels: jw.current.getQualityLevels(),
          });
        });
      }

      /**
       * Airplay Detection
       */
      if (window.WebKitPlaybackTargetAvailabilityEvent) {
        video.current.addEventListener(
          "webkitplaybacktargetavailabilitychanged",
          function (e) {
            switch (e.availability) {
              case "available":
                setCastType("airplay");
                break;
              default:
              // Pass through for typescript
            }
          },
          false,
        );
      }

      if (initial.current.onReady) {
        initial.current.onReady(jw.current, video.current);
      }

      return () => jw.current.remove();
    });
  }

  function resize() {
    if (!jw.current) {
      return;
    }
    jw.current.resize(props.width, props.height);
  }

  function updatePlaying() {
    if (!jw.current) {
      return;
    }
    // TODO: props.playing can get out of
    // sync with jw.current.getState()
    // when casting to an external display
    if (props.playing) {
      jw.current.play();
    } else {
      jw.current.pause();
    }
  }

  function updateMuted() {
    if (!jw.current) {
      return;
    }
    jw.current.setMute(props.muted);
  }

  function updateCastType() {
    if (initial.current.onCastType) {
      initial.current.onCastType(castType);
    }
  }

  function updateSettings() {
    if (!jw.current) {
      return;
    }
    jw.current.setCurrentQuality(props.setting);
  }

  function toggleCast() {
    if (!video.current || casting.current === props.casting) {
      return;
    }
    jw.current.castToggle();
    casting.current = props.casting;
  }

  function handleClick() {
    if (!clickTimeout) {
      return;
    }
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
    }
    var current = clicks.current++;
    clickTimeout.current = setTimeout(function () {
      if (current > 0) {
        // Multi-click
        if (props.onDoubleClick) {
          props.onDoubleClick();
        }
      } else {
        // Regular click
        if (props.onSingleClick) {
          props.onSingleClick();
        }
      }
      clicks.current = 0;
    }, 500); // Milliseconds to allow double click
  }

  return (
    <StyledVideoPlayer
      ref={domElement}
      className="video-player"
      width={props.width}
      height={props.height}
      onPointerMove={props.onMouseMove}
      onPointerUp={handleClick}
      style={{ width: props.width, height: props.height }}
    >
      <div id={id} />
    </StyledVideoPlayer>
  );
}
