import React, { useState, useEffect } from "react";

import Firebase from "../lib/Firebase";

import { StreamIQChat } from "../components/streamiq-chat";
import PipUI from "../components/pip-ui";

const DURATION_THRESHOLD_IN_MILLIS = 3000;

const MAKE_VIEW_REPORT_INTERVAL_IN_MILLIS = 60000;

enum StreamURLTitle {
  Performer = "Performer View",
  Ambient = "Ambient View",
}

declare global {
  interface Window {
    reportInterval: any;
  }
}

interface DuringShowProps {
  idToken: string;
  showId: string;
  ticketId: string;
  playlist: Playlist[] | undefined;
  showConfig?: any;
  user: any;
}

interface Playlist {
  title: StreamURLTitle;
  file: string;
}

export default function DuringShow({
  idToken,
  showId,
  ticketId,
  playlist,
  user,
}: DuringShowProps) {
  // Add back `setButtonImage` when we sync all styles from S3 to the relevant UI elements.
  const [buttonImage] = useState<String>("");

  // Message state to be updated when Firebase comms receives a message
  // that another user has "voted".
  const [notifications] = useState(["hello world"]);

  // States specific to StreamIQ chat functionality
  const [chat, setChat] = useState<any>(null);

  useEffect(() => {
    var hasPrereqs = user && idToken;

    if (hasPrereqs && !chat) {
      const newChat = new StreamIQChat(
        Firebase.firebase.firestore,
        idToken,
        user,
        {},
      );
      setChat(newChat);
    }
  }, [user, idToken, chat]);

  useEffect(() => {
    if (ticketId === "" || showId === "") {
      return;
    }

    const reportInterval = setInterval(() => {
      Firebase.makeViewReport(ticketId, showId);
    }, MAKE_VIEW_REPORT_INTERVAL_IN_MILLIS);

    return () => clearInterval(reportInterval);
  }, [showId, ticketId]);

  // TODO: to implement
  // function handleInteractions() {

  // }

  // See /views/README.md for more details
  function sendNotification(
    direction: String,
    side: String,
    velocity: Number,
    duration: Number,
  ) {
    // Take the event information and broadcast it
    // through firebase to other users.

    const dur = duration < DURATION_THRESHOLD_IN_MILLIS ? "short" : "long";
    const messageToStreamIQ = `${side}-${dur}`;

    if (chat !== null) {
      // Actually send information to StreamIQ
      chat.interact(messageToStreamIQ);
    }
  }

  return (
    <div id="show">
      {playlist && (
        <PipUI
          playlist={playlist}
          enterButtonImage={buttonImage}
          notifications={notifications}
          // The following properties should only be assigned on `mount`
          fadeColor={"#78FF8E"}
          onInteraction={sendNotification}
        />
      )}
    </div>
  );
}
